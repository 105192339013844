.App {
  padding-left: 2em;
  padding-right: 2em;
}

.main {
  padding-top: 88px;
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(200px, 600px));
  justify-content: center;
}
