.loginContainer { 
  background: var(--main-backround);
  height: calc(100vh - 100px);
  max-height: 40em;
  width: 100%;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  display: grid;
  align-items: center;
  justify-items: center;
}

.main {
  padding: 2em 2em;
  // height: 100%;
  overflow: auto;
  text-align: center;
  background: var(--main-background);
  color: white;
  button {
    padding: 0.5em 1.5em;
    border-radius: 0.15em !important;
  }
}

.title {
  font-size: 2.7em;
  font-weight: bold;
  padding: 10px 0;
}
.msg {
  font-size: 1.5em;
  padding: 20px 0;
}
