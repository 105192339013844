@import-sanitize;
@import "sanitize/forms";
@import "sanitize/typography";

html {
  --main-background: #032734;
  color: white;
}
.showingModal {
  overflow: hidden;
}
body {
  background-color: var(--main-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
