.componentContainer {
  border: 1px solid;
  height: 450px;
  display: grid;
  grid-template-rows: auto 1fr;
  :global {
    .head {
      border-bottom: 1px solid;
      min-height: 2em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 1em;
      .fa {
        line-height: inherit;
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }
  }
}
