.calcolo {
  table {
    width: 100%;
  }
  td,
  th {
    padding: 0.5em;
    border: 1px solid;
  }
}
