.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  overflow: auto;
}

.configurationModal {
  display: grid;
  justify-self: center;
  align-self: center;
  background-color: var(--main-background);
  width: 450px;
  height: 400px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  :global {
    select {
      border-radius: 0;
    }
    option {
      background-color: var(--main-background);
    }
    .modal-header {
      border-bottom: 1px solid;
      padding: 0.5em 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .modal-body {
      padding: 1em;
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }
    .modal-footer {
      border-top: 1px solid;
      padding: 0.5em 1em;
      display: flex;
      justify-content: flex-end;
      gap: 0.5em;
    }
  }
}
