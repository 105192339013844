.component {
  select {
    border-radius: 0;
    path {
      stroke: white;
      fill: white;
    }
    &:focus-visible {
      outline: initial;
    }
  }
  option {
    background-color: var(--main-background);
  }
}

