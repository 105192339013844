.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 88px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1em;
  padding-left: 2em;
  background-color: var(--main-background);
  :global {
    .logo-section {
      height: 88px;
      .logo {
        height: 100%;
        padding: 1em 0;
      }
    }
    .header-menu {
      position: relative;

      > .header {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-left: 1px solid;
        padding-left: 1em;
        height: 100%;
        .icon {
          font-size: 1.5em;
          padding-right: 0.5em;
        }
      }
      .body {
        z-index: 100;
        padding: 0.2em 0.8em;
        box-shadow: 0 3px 10px black;
        position: absolute;
        right: -1em;
        top: 2.9em;
        min-width: 13em;
        display: grid;
        grid-template-areas: "content";
        overflow: hidden;
        background-color: var(--main-background);
        .main {
          grid-area: content;
          gap: 0.25em;
          display: flex;
          flex-direction: column;
          .userInfo {
            font-size: 10px;
            white-space: nowrap;
            padding: 0 1em;
            padding-bottom: 1em;
          }
          .email {
            font-weight: 600;
          }
          .signOut {
            padding: 0.4em 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border: none;
            .icon {
              padding-left: 0.4em;
            }
          }
        }
      }
    }
    .userContainer {
      padding: 0 0.2em;
      display: flex;
      flex-direction: column;
      justify-content: right;
      align-items: flex-end;
    }
    .header-menu-header {
      font-weight: 600;
      white-space: nowrap;
    }
  }
}

.companyAndUserSelect {
  display: flex;
  justify-self: flex-end;
  height: fit-content;
  align-self: center;
  > div {
    padding-right: 1em;
  }
}
