.calcolo {
  table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-bottom: 1px solid;
    &.mapeTable{
      margin: 15px 0 0 0 ;
      border-top: 1px solid;
      border-bottom: 1px solid;
    }
  }
  td,
  th {
    padding: 0.5em;
    border: 1px solid;
  }

}
